/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

@charset "utf-8";

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Bold.woff2") format("woff2"),
    url("../fonts/Gotham-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Book";
  src: url("../fonts/Gotham-Book.woff2") format("woff2"),
    url("../fonts/Gotham-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

// Colors

$black: hsl(0, 0%, 4%);
$black-bis: hsl(0, 0%, 7%);
$black-ter: hsl(0, 0%, 14%);

$grey-darker: hsl(0, 0%, 21%);
$grey-dark: hsl(0, 0%, 29%);
$grey: hsl(0, 0%, 48%);
$grey-light: hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);

$white-ter: hsl(0, 0%, 96%);
$white-bis: hsl(0, 0%, 98%);
$white: hsl(0, 0%, 100%);

$orange: hsl(14, 100%, 53%);
$yellow: hsl(48, 100%, 67%);
$green: hsl(141, 71%, 48%);
$turquoise: hsl(171, 100%, 41%);
$cyan: hsl(204, 86%, 53%);
$purple: hsl(271, 100%, 71%);
$red: hsl(348, 100%, 61%);
$button-background-color: rgba(22, 22, 50, 0.02);
$brown: #cd7f32;
$blue: #161632;
$blueLight: #2e2e47;

// Typography

$family-monospace: monospace;
$render-mode: optimizeLegibility;

$size-1: 3rem;
$size-2: 2.5rem;
$size-3: 2rem;
$size-4: 1.5rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 0.75rem;
$size-8: 0.625rem;
$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;

// Responsiveness

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px;
// 960px container + 4rem
$desktop: 960px + (2 * $gap);
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap);
$widescreen-enabled: true;
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap);
$fullhd-enabled: true;

// Miscellaneous

$easing: ease-out;
$radius-small: 4px;
$radius: 4px;
$radius-large: 4px;
$radius-rounded: 290486px;
$speed: 86ms;

// Flags
$variable-columns: true;
$primary: $blue;
$link: $blueLight;

// Update some of Bulma's component variables
$body-background-color: #fafbfc;
$control-border-width: 1px;
//$input-border-color: transparent;
$input-shadow: none;
$table-cell-border-width: 0;
$table-head-cell-border-width: 0 0 1px 0;
$box-shadow: 0 0 7px 0 rgba(22, 22, 50, 0.05);
$box-shadow-hover: 0 0 7px 0 rgba(22, 22, 50, 0.12);

// Update Bulma's global variables
$family-sans-serif: "Gotham Book";
$family-bold: "Gotham";
$grey-dark: #696969;
$grey-light: #161632;
$primary: $blue;
$link: $blueLight;
$widescreen-enabled: false;
$fullhd-enabled: false;
$paddingContainer: 1rem;
$material-icons-font-path: "~material-icons/iconfont/";

// z-index
$layer-modal: 8;
$layer-blocker: 7;
$layer-burger: 6;
$layer-sticky: 5;
$layer-tooltip: 4;
$layer-screen-attached: 3;
$layer-blur-handler: 2;
$layer-content-attached: 1;
$layer-content: 0;

@import "../../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../../node_modules/bulma/sass/base/_all.sass";
@import "../../../node_modules/bulma/sass/form/_all.sass";
@import "../../../node_modules/bulma/sass/layout/_all.sass";
@import "../../../node_modules/bulma/sass/components/_all.sass";
@import "../../../node_modules/bulma/sass/elements/_all.sass";
@import "../../../node_modules/bulma/sass/grid/_all.sass";

.tag.has-background-brown {
  background: $brown;
  strong {
    color: $white;
  }
}

.container-padding {
  padding-top: $gap;
}

.box.is-hoverable {
  &:hover {
    box-shadow: $box-shadow-hover;
    cursor: pointer;
  }
}

.table td,
.table th {
  vertical-align: middle;
}

.dropdown.is-fullwidth {
  display: flex;
}

.dropdown.is-fullwidth * {
  width: 100%;
}

.has-tooltip {
  position: relative;
  .tooltip {
    display: none;
  }
  &:hover {
    cursor: pointer;
    .tooltip {
      display: block;
      position: absolute;
      transform: translateX(100%);
      top: 0px;
      padding-left: 20px;
      right: 0px;
      z-index: 10000;
    }
  }
}

.has-hover {
  position: relative;
  .hover {
    display: none;
    position: absolute;
    right: 0px;
  }
  &:hover {
    .hover {
      display: block;
      right: 0%;
      top: 50%;
      transform: translate(-0%, -50%);
      &.small {
        top: 100%;
        transform: translate(-0%, -0%);
      }
    }
  }
}

.footer {
  margin-top: 5rem;
  background: #fff;
}

.main {
  min-height: calc(100vh - 15rem);
}

.is-provider-logo {
  width: 40px;
  height: auto;
}

.element {
  &.is-loading {
    position: relative;
    pointer-events: none;
    opacity: 0.5;
    color: $blue;
    &:after {
      @include loader;
      position: absolute;
      top: calc(50% - 0.8em);
      left: calc(50% - 0.8em);
      width: 1.6em;
      height: 1.6em;
      border-width: 0.25em;
    }
  }
}

.loader {
  width: 50px;
  height: 50px;
}

.customer-selector {
  li {
    a:hover {
      color: red !important;
    }
  }
}

.customer-selector-bottom {
  a {
    color: #fff;
    &:hover {
      color: red;
    }
  }
}

.resource-list {
  .form-title {
    margin-bottom: 1.5rem;
    justify-content: space-between;
    cursor: pointer;
  }

  .box {
    margin-bottom: 1.5rem;

    .is-centre {
      text-align: center;
    }
  }
}

.resource-list-buttons {
  .button {
    margin-bottom: 1.5rem;
  }
}

.button {
  font-size: 0.625rem;
  font-family: "Gotham";
  background-color: rgba(22, 22, 50, 0.02);
  border: solid 1px rgba(22, 22, 50, 0.02);
  text-transform: uppercase;
  &.is-white {
    background: #ffffff;
    border: solid 1px rgba(22, 22, 50, 0.1);
  }

  &:hover {
    border: solid 1px rgba(22, 22, 50, 0.02);

    background: #fff;
  }

  height: 30px;
  color: #696969;
  margin-left: 8px;
  i {
    margin-right: 5px;
    &.right {
      margin-left: 1rem;
      margin-right: 0;
      font-size: 20px;
    }
  }
  &.has-besel {
    &:before {
      position: absolute;
      width: 40px;
      left: 0;
      top: 0;
      height: 100%;
      background: rgba(0, 0, 0, 0.015);
      content: "";
    }
    &:after {
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
    }
  }
  &.is-action {
    height: 45px;
    padding: 0 20px 0 10px;
    background: #f5f5f5 !important;
    font-size: 0.7rem;
    color: #363636 !important;
    &:hover {
      background: #fff !important;
    }
    i {
      font-size: 1em;
      margin-right: 1.4rem;
      transform: translate(5px, 1px);
      color: #363636;
      margin-right: 3.3em;
    }
    &:hover {
      opacity: 0.9;
    }
    * {
    }
  }
  &.is-large {
    height: 3rem;
  }
  &.main-cta {
    margin-left: 0px;
    margin-bottom: 2rem;
  }

  &.is-centered {
    display: block;
    margin: auto;
  }

  .loader {
    height: 30px;
  }
}
.steps .step-item .step-marker {
  background-color: #b5b5b5;
}

.action-cell {
  display: flex;

  button:not(:first-child),
  .select:not(:first-child),
  select:not(:first-child) {
    margin-left: 0.3rem;
  }
}

.status-title {
  line-height: 3rem;

  button.is-action {
    padding: 0 20px;
  }
}

.status-message {
  padding: 0 10px;
  border-radius: 5px;
}

.title.has-background-light {
  font-size: 1rem;
  padding: 0.5rem;
}

.form-title {
  display: flex;
  align-items: center;
}

.policy-select,
.setup-select {
  margin-right: 3px;
  outline: none;
  .select {
    border-radius: 3px;
    width: 155px;
    margin-left: 3px;
    background-color: white;
    border: 1px solid #dbdbdb;
    height: 2.25rem;
    line-height: 1.5;
    display: inline-flex;
    justify-content: flex-start;
    padding-bottom: calc(0.375em - 1px);
    padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px);
    padding-top: calc(0.375em - 1px);
    position: relative;

    &:hover {
      border-color: #161632;
    }

    .policy-list,
    .setup-list {
      position: absolute;
      top: 100%;
      right: -50%;
      background-color: white;
      min-width: 350px;
      z-index: 666;
      padding: 10px 15px;

      tbody tr {
        &:hover {
          box-shadow: $box-shadow-hover;
          cursor: pointer;
        }
      }
    }
  }
}

.has-bg {
  padding-left: 8rem;
  position: relative;
  .settings-icon {
    width: 4rem;
    height: auto;
    left: 2rem;
    top: 50%;

    transform: translateY(-50%);
    position: absolute;
  }
}

.account-box {
  cursor: pointer;
}

.account-box.disabled {
  cursor: not-allowed;
  background-color: $grey-lighter;
}

.button.is-icononly {
  padding: 8px 6px 8px 10px;
}

.table {
  .tag {
    margin: 2px;
  }
}

.modal {
  height: 100vh;

  width: 100vw;
  left: 0;
  top: 0;
  position: fixed;
}

.resource-tag {
  span {
    cursor: pointer;
    font-size: 1rem;
    margin: 2px;

    i {
      padding-right: 5px;
    }
  }
}

.steps {
  li {
    cursor: pointer;
  }
}

.modal {
  cursor: auto;
  .modal-close-btn {
    cursor: pointer;
  }
}

.new-setup-button {
  margin-bottom: 1rem;
}

.setup-row {
  cursor: pointer;

  &:hover {
    box-shadow: $box-shadow-hover;
  }
}

.pagination-list {
  justify-content: center;
}

.chart {
  height: 200px;
  width: 200px;
}

ul {
  li {
    li {
      padding-left: 2rem;
      margin-bottom: 2rem;
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }
}

.subtitle + .table.is-fullwidth {
  margin-top: -1.25rem;
}

.subtitle.clickable {
  cursor: pointer;
}

tbody {
  th {
    white-space: nowrap;
  }
}

table.is-clickable {
  tbody {
    tr {
      &:hover {
        td,
        th {
          background: rgba(0, 0, 0, 0.07);
          cursor: pointer;
        }
        &.expandable {
          td,
          th {
            background: rgba(252, 255, 180, 0.7);
          }
        }
      }
    }
  }
}

.clipboard {
  position: relative;
  &:hover {
    cursor: pointer;
    &:after {
      display: block;
      width: 20px;
      height: 20px;
      font: normal normal normal 14px/1 FontAwesome;
      content: "\f0ea";
      position: absolute;
      right: -25px;
      top: 0px;
    }
  }
}

.table-link {
  text-decoration: underline;
  &:hover {
    font-weight: bold;
  }
}
